export const GET_ENVIRONMENT = `
  query {
    environment {
      helpUrl
      partnerUrl
      storeBaseDomain
      cookiePolicyUrl
      devHelpUrl
    }
  }
`;
